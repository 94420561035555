import { ApplicationNames } from '@constants/applications/applicationNames'
import { SkeletonDarkStyles, SkeletonLightStyles } from '@styles/skeleton/skeleton'
import { borderRadius } from '@themes/borderRadius'
import { colorsList } from '@themes/colors/colorsList'
import { bluePrimary, yellowPrimary } from '@themes/colors/primaryColors'
import { fontFamily } from '@themes/fontFamily'
import { getGradient, gradient } from '@themes/gradient'
import { mediaQueries } from '@themes/mediaQueries'
import { opacityBase, opacityRailNinja } from '@themes/opacity'
import { padding } from '@themes/padding'
import {
  darkThemeShadows,
  lightThemeShadows,
  railNinjaDarkThemeShadows,
  railNinjaLightThemeShadows,
} from '@themes/shadows'
import { sizes } from '@themes/sizes'
import { transitions } from '@themes/transitions'

//always use either 'light' or 'dark' in the theme title, necessary for captcha work
export enum ThemeNames {
  DARK = 'dark',
  LIGHT = 'light',
}

const lightThemeColors = {
  backgroundAdditional: colorsList.greyFrosty,
  backgroundAdditionalForm: colorsList.greyFrosty,
  backgroundAdditionalFormHover: colorsList.greyFrosty,
  backgroundAdditionalLogo: colorsList.additionalBlueLogo,
  backgroundBlock: 'transparent',
  backgroundBlueContainer: colorsList.blueLite,
  backgroundBorder: colorsList.blueLite,
  backgroundButtonContainer: colorsList.blueLite,
  backgroundContainer: colorsList.white,
  backgroundContrast: colorsList.black,
  backgroundEmptyLabel: colorsList.white,
  backgroundGradientBlue: colorsList.gradientBlue,
  backgroundGradientBlueOpacity: colorsList.gradientBlueOpacity,
  backgroundImage: 'inherit',
  backgroundImageService: 'invert(1)',
  backgroundImageYellow: 'inherit',
  backgroundInput: colorsList.white,
  backgroundLightContainer: colorsList.greyPureWhite,
  backgroundMain: colorsList.white,
  backgroundMenuContent: colorsList.greyLight,
  backgroundNotification: colorsList.greyMedium,
  backgroundOverflow: colorsList.overflowOpacityLight,
  backgroundOverlayModal: colorsList.transparentBlack,
  backgroundPage: colorsList.white,
  backgroundPageGradient: colorsList.white,
  backgroundRangePicker: colorsList.blueLite,
  backgroundReviewContainer: colorsList.greyPureWhite,
  backgroundSwitcher: colorsList.white,

  // Skeleton
  bgSkeleton: SkeletonLightStyles,

  // borders
  border: colorsList.blueLite,
  border50: colorsList.blueLite,
  borderBlue: colorsList.blueLite,
  borderDark: colorsList.white,
  borderFrosty: colorsList.greyFrosty,
  borderGrey: colorsList.greySilver,
  borderGreyLight: colorsList.greyBorderLight,
  borderLight: colorsList.greySilver,

  // buttons
  buttonDownload: colorsList.blackLite,
  buttonIconDisabled: colorsList.greySilver,
  buttonPlay: colorsList.white,
  buttonText: colorsList.blue100,
  buttonTextForm: colorsList.blue100,

  // checkbox
  checkboxSecondary: colorsList.white,

  // text
  fontContrast: colorsList.black,
  fontEmptyLabel: colorsList.greyCarbon,
  fontMain: colorsList.blackLite,
  fontPopularRoutes: colorsList.greyCarbon,
  fontRoutes: colorsList.greyCarbon,
  fontSecondary: colorsList.greyCarbon,
  fontSecondaryForm: colorsList.greyCarbon,
  fontSecondaryLight: colorsList.greySilver,

  //footer
  footer: getGradient(0.03).grayToWhite,
  icon: colorsList.blue100,
  iconCarrierFilter: 'grayscale(100%)',
  iconForm: colorsList.greyIcon,
  iconReview: colorsList.blue100,

  // main
  ...bluePrimary,

  //collapse
  collapseBody: colorsList.greyLight,
  collapseTimeLineTail: colorsList.black25,

  // trip switcher
  switcherBackground: colorsList.white,

  // fastest trains time line
  timeLine: colorsList.blueLite,
  timeLineActive: colorsList.blueLite,
  timeLineDouble: colorsList.greySilver,

  ...colorsList,
}

const railNinjaLightThemeColors = {
  backgroundAdditional: colorsList.yellowFrosty,
  backgroundAdditionalForm: colorsList.white,
  backgroundAdditionalFormHover: colorsList.yellowFrosty,
  backgroundAdditionalLogo: colorsList.additionalLogo,
  backgroundBlock: colorsList.greyBlock,
  backgroundBlueContainer: yellowPrimary.primary25,
  backgroundBorder: 'transparent',
  backgroundButtonContainer: 'transparent',
  backgroundContainer: colorsList.white,
  backgroundContrast: colorsList.black,
  backgroundEmptyLabel: colorsList.white,
  backgroundGradientBlue: 'transparent',
  backgroundGradientBlueOpacity: 'transparent',
  backgroundImage: 'brightness(0) invert(1)',
  backgroundImageService: 'inherit',
  backgroundImageYellow: 'invert(1)',
  backgroundInput: colorsList.white,
  backgroundLightContainer: colorsList.greyPureWhite,
  backgroundMain: colorsList.white,
  backgroundMenuContent: colorsList.greyLight,
  backgroundNotification: colorsList.yellowFrosty,
  backgroundOverflow: colorsList.overflowOpacityLight,
  backgroundOverlayModal: colorsList.transparentBlack,
  backgroundPage: colorsList.white,
  backgroundPageGradient: colorsList.white,
  backgroundRangePicker: yellowPrimary.primary25,
  backgroundReviewContainer: colorsList.yellow100,
  backgroundSwitcher: colorsList.white,

  // Skeleton
  bgSkeleton: SkeletonLightStyles,

  // borders
  border: yellowPrimary.primary25,
  border50: yellowPrimary.primary25,
  borderBlue: yellowPrimary.primary25,
  borderDark: yellowPrimary.primary,
  borderFrosty: colorsList.greyFrosty,
  borderGrey: colorsList.greySilver,
  borderGreyLight: colorsList.greyBorderLight,
  borderLight: colorsList.greySilver,

  // buttons
  buttonDownload: colorsList.blackLite,
  buttonIconDisabled: colorsList.greySilver,
  buttonPlay: colorsList.white,
  buttonText: colorsList.yellow100,
  buttonTextForm: colorsList.white,

  // checkbox
  checkboxSecondary: colorsList.white,

  // text
  fontContrast: colorsList.black,
  fontEmptyLabel: colorsList.greyCarbon,
  fontMain: colorsList.blackLite,
  fontPopularRoutes: colorsList.greyCarbon,
  fontRoutes: colorsList.greyCarbon,
  fontSecondary: colorsList.greyCarbon,
  fontSecondaryForm: colorsList.greyAdditionalTextLight,
  fontSecondaryLight: colorsList.greySilver,

  //footer
  footer: getGradient(0.03).grayToWhite,
  icon: colorsList.yellow100,
  iconCarrierFilter: 'grayscale(100%)',
  iconForm: colorsList.greyIcon,
  iconReview: colorsList.white,

  // main
  ...yellowPrimary,

  //collapse
  collapseBody: colorsList.greyPureWhite,
  collapseTimeLineTail: colorsList.black25,

  // trip switcher
  switcherBackground: colorsList.yellow100,

  // fastest trains time line
  timeLine: yellowPrimary.primary25,
  timeLineActive: yellowPrimary.primary25,
  timeLineDouble: colorsList.greySilver,

  ...colorsList,
}

const satelliteLightThemeColors = {
  ...railNinjaLightThemeColors,
  fontMain: colorsList.black,
  iconForm: colorsList.blackOpacity25,
}

const darkThemeColors = {
  // backgrounds
  backgroundAdditional: colorsList.deepGrey,
  backgroundAdditionalForm: colorsList.deepGrey,
  backgroundAdditionalFormHover: colorsList.deepGrey,
  backgroundAdditionalLogo: colorsList.additionalBlueLogo,
  backgroundBlock: 'transparent',
  backgroundBlueContainer: colorsList.semiGrey,
  backgroundBorder: colorsList.semiGrey,
  backgroundButtonContainer: colorsList.semiGrey,
  backgroundContainer: colorsList.semiGrey,
  backgroundContrast: colorsList.white,
  backgroundEmptyLabel: colorsList.blueDeep,
  backgroundGradientBlue: 'transparent',
  backgroundGradientBlueOpacity: 'transparent',
  backgroundImage: 'inherit',
  backgroundImageService: 'invert(1)',
  backgroundImageYellow: 'inherit',
  backgroundInput: colorsList.blackLight,
  backgroundLightContainer: colorsList.semiGrey,
  backgroundMain: colorsList.grey,
  backgroundMenuContent: colorsList.semiGrey,
  backgroundNotification: colorsList.semiGrey,
  backgroundOverflow: colorsList.overflowOpacityDark,
  backgroundOverlayModal: colorsList.transparentBlack,
  backgroundPage: colorsList.mainDark,
  backgroundPageGradient: gradient.darkBlueToBlack,
  backgroundRangePicker: colorsList.semiGrey,
  backgroundReviewContainer: colorsList.semiGrey,
  backgroundSwitcher: 'transparent',

  // Skeleton
  bgSkeleton: SkeletonDarkStyles,

  // borders
  border: colorsList.greyBorder,
  border50: colorsList.grey50,
  borderBlue: colorsList.blueDark,
  borderDark: colorsList.mainDark,
  borderFrosty: colorsList.blueDark,
  borderGrey: colorsList.grey,
  borderGreyLight: colorsList.greyBorderLight,
  borderLight: colorsList.semiGrey,

  // buttons
  buttonDownload: colorsList.blue100,
  buttonIconDisabled: colorsList.greyCommon,
  buttonPlay: colorsList.white,
  buttonText: colorsList.white,
  buttonTextForm: colorsList.white,

  // checkbox
  checkboxSecondary: colorsList.grey,

  // text
  fontContrast: colorsList.mainDarkGrey,
  fontEmptyLabel: colorsList.white,
  fontMain: colorsList.white,
  fontPopularRoutes: colorsList.greyCarbon,
  fontRoutes: colorsList.greyCarbon,
  fontSecondary: colorsList.mainDarkGrey,
  fontSecondaryForm: colorsList.mainDarkGrey,
  fontSecondaryLight: colorsList.mainDarkGrey,

  //footer
  footer: colorsList.deepGrey,
  icon: colorsList.blueContrast,
  iconCarrierFilter: 'grayscale(100%)',
  iconForm: colorsList.greyIcon,
  iconReview: colorsList.blueContrast,

  // main
  ...bluePrimary,

  //collapse
  collapseBody: colorsList.blackLight,
  collapseTimeLineTail: colorsList.whiteOpacity25,

  // trip switcher
  switcherBackground: colorsList.blue100,

  // fastest trains time line
  timeLine: colorsList.blue100,
  timeLineActive: colorsList.blue100,
  timeLineDouble: colorsList.blue100,

  ...colorsList,
}

const railNinjaDarkThemeColors = {
  backgroundAdditional: colorsList.yellowFrostyDark,
  backgroundAdditionalForm: colorsList.blackBackground,
  backgroundAdditionalFormHover: colorsList.blackBackground,
  backgroundAdditionalLogo: colorsList.additionalLogo,
  backgroundBlock: colorsList.blackBlock,
  backgroundBlueContainer: yellowPrimary.primary25,
  backgroundBorder: 'transparent',
  backgroundButtonContainer: 'transparent',
  backgroundContainer: colorsList.blackBlock,
  backgroundContrast: colorsList.white,
  backgroundEmptyLabel: colorsList.blueDeep,
  backgroundGradientBlue: 'transparent',
  backgroundGradientBlueOpacity: 'transparent',
  backgroundImage: 'brightness(0) invert(1)',
  backgroundImageService: 'inherit',
  backgroundImageYellow: 'invert(1)',
  backgroundInput: colorsList.blackBlock,
  backgroundLightContainer: colorsList.blackBlock,
  backgroundMain: colorsList.blackBlock,
  backgroundMenuContent: colorsList.blackBlock,
  backgroundNotification: colorsList.blackBlock,
  backgroundOverflow: colorsList.overflowOpacityDark,
  backgroundOverlayModal: colorsList.transparentBlack,
  backgroundPage: colorsList.blackBackground,
  backgroundPageGradient: colorsList.blackBackground,
  backgroundRangePicker: colorsList.yellowFrostyDark,
  backgroundReviewContainer: colorsList.yellow100,
  backgroundSwitcher: 'transparent',

  // Skeleton
  bgSkeleton: SkeletonDarkStyles,

  // borders
  border: yellowPrimary.primary25,
  border50: yellowPrimary.primary25,
  borderBlue: yellowPrimary.primary25,
  borderDark: yellowPrimary.primary,
  borderFrosty: colorsList.greyFrosty,
  borderGrey: colorsList.greySilver,
  borderGreyLight: colorsList.greyBorderLight,
  borderLight: colorsList.greySilver,

  // buttons
  buttonDownload: colorsList.blackLite,
  buttonIconDisabled: colorsList.greySilver,
  buttonPlay: colorsList.blackBackground,
  buttonText: colorsList.yellow100,
  buttonTextForm: colorsList.white,

  // checkbox
  checkboxSecondary: colorsList.white,

  // text
  fontContrast: colorsList.mainDarkGrey,
  fontEmptyLabel: colorsList.white,
  fontMain: colorsList.white,
  fontPopularRoutes: colorsList.greyAdditionalTextDark,
  fontRoutes: colorsList.greyAdditionalTextDark,
  fontSecondary: colorsList.greyAdditionalTextDark,

  fontSecondaryForm: colorsList.greyAdditionalTextDark,
  fontSecondaryLight: colorsList.mainDarkGrey,

  //footer
  footer: colorsList.blackBlock,
  icon: colorsList.yellow100,
  iconCarrierFilter: 'grayscale(100%) invert(1)',
  iconForm: colorsList.greyIcon,
  iconReview: colorsList.white,

  // main
  ...yellowPrimary,

  //collapse
  collapseBody: colorsList.blackBlock,
  collapseTimeLineTail: colorsList.whiteOpacity25,

  // trip switcher
  switcherBackground: colorsList.yellow100,

  // fastest trains time line
  timeLine: yellowPrimary.primary25,
  timeLineActive: yellowPrimary.primary25,
  timeLineDouble: colorsList.greySilver,

  ...colorsList,
}

export interface ITheme {
  borderRadius: typeof borderRadius
  colors: typeof lightThemeColors
  fontFamily: typeof fontFamily
  gradient: ReturnType<typeof getGradient> & typeof gradient
  mediaQueries: typeof mediaQueries
  opacity: typeof opacityBase
  padding: typeof padding
  shadows: typeof lightThemeShadows
  sizes: typeof sizes
  transitions: typeof transitions
}

export type ThemeColorsType = keyof typeof lightTheme.colors

const baseLightTheme = {
  borderRadius,
  gradient: { ...getGradient(0.05), ...gradient },
  mediaQueries,
  padding,
  sizes,
  transitions,
}

const baseDarkTheme = {
  borderRadius,
  gradient: { ...getGradient(0.15), ...gradient },
  mediaQueries,
  padding,
  sizes,
  transitions,
}

export const lightTheme: ITheme = {
  ...baseLightTheme,
  colors: lightThemeColors,
  fontFamily: fontFamily,
  opacity: opacityBase,
  shadows: lightThemeShadows,
}

export const darkTheme: ITheme = {
  ...baseDarkTheme,
  colors: darkThemeColors,
  fontFamily: fontFamily,
  opacity: opacityBase,
  shadows: darkThemeShadows,
}

export const railNinjaLightTheme: ITheme = {
  ...baseLightTheme,
  colors: railNinjaLightThemeColors,
  fontFamily: fontFamily,
  opacity: opacityBase,
  shadows: railNinjaLightThemeShadows,
}

export const railNinjaDarkTheme: ITheme = {
  ...baseDarkTheme,
  colors: railNinjaDarkThemeColors,
  fontFamily: fontFamily,
  opacity: opacityRailNinja,
  shadows: railNinjaDarkThemeShadows,
}

export const satelliteLightTheme: ITheme = {
  ...baseLightTheme,
  colors: satelliteLightThemeColors,
  fontFamily: fontFamily,
  opacity: opacityBase,
  shadows: railNinjaLightThemeShadows,
}

export const themes: Record<ApplicationNames, Record<ThemeNames, ITheme>> = {
  [ApplicationNames.HST]: {
    [ThemeNames.DARK]: darkTheme,
    [ThemeNames.LIGHT]: lightTheme,
  },
  [ApplicationNames.RN_CLASSIC]: {
    [ThemeNames.DARK]: railNinjaDarkTheme,
    [ThemeNames.LIGHT]: railNinjaLightTheme,
  },
  [ApplicationNames.RN_MODERN]: {
    [ThemeNames.DARK]: railNinjaDarkTheme,
    [ThemeNames.LIGHT]: railNinjaLightTheme,
  },
  [ApplicationNames.RN_NEW]: {
    [ThemeNames.DARK]: railNinjaDarkTheme,
    [ThemeNames.LIGHT]: railNinjaLightTheme,
  },
  [ApplicationNames.SEARCH_WIDGET]: {
    [ThemeNames.DARK]: satelliteLightTheme,
    [ThemeNames.LIGHT]: satelliteLightTheme,
  },
}
