export const colorsList = {
  additionalBlueLogo: '#0b2e7b',
  additionalLogo: '#79591f',
  black: '#000',
  black0: 'rgba(0, 0, 0, 0)',
  black05: 'rgba(0,0,0,0.05)',
  black25: 'rgba(0, 0, 0, 0.25)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black40: 'rgba(0, 0, 0, 0.4)',
  black60: 'rgba(0, 0, 0, 0.6)',
  black80: 'rgba(0, 0, 0, 0.8)',
  blackBackground: '#030303',
  blackBlock: '#292929',
  //blackBlue: '#232631', never used
  blackLight: '#262A35',
  blackLite: '#191919',
  blackModern: '#1a1a1a',
  blackOpacity05: '#0000000D',
  blackOpacity25: '#00000040',
  blue100: '#165DF6',
  blueContrast: '#3573F9',
  blueDark: '#2E4A8A',
  blueDeep: '#141c2e',
  blueLite: '#DFE8FD',
  blueNight: '#002444',
  darkCharcoal: '#313131',
  deepGrey: '#161A25',
  error: '#D22C35',
  errorLight: '#faeae9',
  gradientBlue: 'rgba(22,93,246,0.15)',
  gradientBlueOpacity: 'rgba(22,93,246,0)',
  green: '#62b423',
  green2: '#07b54a',
  greenHarp: '#e5f1ec',
  grey: '#2B2F3C',
  grey04: '#0000000a',
  grey50: '#2B2F3C80',
  grey8D: '#8D8D8D',
  grey8DOpacity50: '#8D8D8D80',
  greyAdditionalTextDark: '#E6E6E6',
  greyAdditionalTextLight: '#818181',
  greyArgent: '#BFBFBF',
  greyBlock: '#f2f2f2',
  greyBorder: '#ADBCDD33',
  greyBorderLight: '#CDCDCD',
  greyCarbon: '#707070',
  greyCommon: '#383D47',
  //greyDark: '#303030',  never used
  greyFrosty: '#F6F8FE',
  greyIcon: '#C8D4CE',
  greyLight: '#F7F7F7',
  greyMedium: '#E8EFFE',
  greyPureWhite: '#F9F9F9',
  greySilver: '#E8E8E8',
  greyTrain: '#dcdcdc',
  mainDark: '#0D101A',
  mainDarkGrey: '#969BA9',
  overflowOpacityDark: 'rgba(0,0,0,0.85)',
  overflowOpacityLight: 'rgba(255,255,255,0.85)',
  red: '#E53122',
  red2: '#b50000',
  semiGrey: '#202531',
  shadow: '#07090F',
  transparentBlack: '#000000BF',
  white: '#FFF',
  whiteOpacity10: 'rgba(255, 255, 255, 0.1)',
  whiteOpacity25: 'rgba(255, 255, 255, 0.25)',
  whiteOpacity50: 'rgba(255, 255, 255, 0.50)',
  //yellow: '#ffae00',  never used
  whiteOpacity60: '#FFFFFF99',
  whiteOpacity80: 'rgba(255, 255, 255, 0.80)',
  yellow: '#ffae00',
  yellow10: '#FFAF01',
  yellow100: '#f3b23e',
  yellowBright: '#FFF7E6',
  yellowFrosty: '#fef8ed',
  yellowFrostyDark: '#4d4331',
  yellowLace: '#FDF3E2',
  yellowOpacity10: '#ffaf011a',
}
