import ConfigProvider from 'antd/es/config-provider'
import type { FC, NamedExoticComponent, ReactElement } from 'react'
import type { ExecutionProps } from 'styled-components'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'

import { useAppSelector } from '@redux/hooks'
import { selectTheme } from '@reduxCommonApp/commonApp.selectors'

import getAppConfig from '@services/config/config'
import DefaultGlobalStyles from '@styles/globalStyles/globalStyles'
import type { ITheme } from '@themes/themes'
import { ThemeNames, themes } from '@themes/themes'

type Props = {
  children: ReactElement
  GlobalStyles?: NamedExoticComponent<ExecutionProps & object>
  theme?: ITheme
}

const ThemeProvider: FC<Props> = ({ children, GlobalStyles = DefaultGlobalStyles, theme: propsTheme }) => {
  const themeKey = useAppSelector(selectTheme)
  const {
    publicRuntimeConfig: { site },
  } = getAppConfig()

  const theme = propsTheme || themes[site][themeKey] || themes[site][ThemeNames.LIGHT]

  return (
    <StyledComponentsThemeProvider theme={theme}>
      <ConfigProvider
        theme={{
          components: {
            Checkbox: {
              borderRadius: 8,
              colorBgContainerDisabled: theme?.colors.checkboxSecondary,
              colorPrimary: theme?.colors.buttonText,
              colorPrimaryHover: theme?.colors.checkboxSecondary,
              colorWhite: theme?.colors.buttonText,
              controlInteractiveSize: 32,
            },
            Divider: {
              colorSplit: theme.colors.fontSecondaryLight,
            },
            Input: {
              colorBgContainer: theme.colors.backgroundInput,
            },
            Menu: {
              borderRadius: theme.borderRadius.smNumber,
              itemActiveBg: theme.colors.grey04,
            },
            Modal: {
              boxShadow: theme.shadows.modal,
              contentBg: theme.colors.backgroundMain,
              headerBg: theme.colors.backgroundMain,
              paddingContentHorizontalLG: 0,
              paddingMD: 0,
            },
            Radio: {
              buttonColor: theme.colors.fontMain,
              colorBgContainer: theme.colors.backgroundMain,
              colorBorder: theme.colors.buttonIconDisabled,
              colorPrimary: theme.colors.primary,
              colorPrimaryHover: theme.colors.primary,
              dotSize: 10,
              radioSize: 24,
            },
            Result: {
              subtitleFontSize: 14,
            },
            Spin: {
              colorPrimary: theme.colors.primary,
            },
          },

          token: {
            borderRadiusLG: theme.borderRadius.defaultNumber,
            boxShadowSecondary: theme.shadows.modal,
            colorBgElevated: theme.colors.backgroundMain,
            colorError: theme.colors.red,
            colorText: theme.colors.fontMain,
            colorTextDescription: theme.colors.fontSecondary,
            fontFamily: theme.fontFamily[site],
          },
        }}
      >
        {children}
        <GlobalStyles />
      </ConfigProvider>
    </StyledComponentsThemeProvider>
  )
}

export default ThemeProvider
