export const borderRadius = {
  default: '16px',
  defaultNumber: 16,
  sm: '8px',
  smNumber: 8,
  xs: '6px',
  xsNumber: 6,
  xxs: '4px',
  xxsNumber: 4,
}
